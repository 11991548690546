import "./Dashboard.css";
import {useNavigate} from "react-router-dom";
import React, {useEffect, useRef, useState} from "react";
import HomePage from "../Pages/HomePage";
import UserView from "../Pages/UserView";
import Settings from "../Pages/Settings";
import {
    Avatar,
    Button,
    Card,
    Form,
    Input,
    message,
    Modal,
    Popconfirm,
    Space,
    Spin,
    Table,
    Tooltip,
    Typography,
} from "antd";
import {
    DeleteOutlined,
    EditOutlined,
    HomeOutlined,
    LogoutOutlined,
    RobotOutlined,
    RocketOutlined,
    SettingOutlined,
    UploadOutlined,
    UserOutlined,
} from "@ant-design/icons";

import bot from "../assets/usia.png";

const {Title, Paragraph} = Typography;

function Dashboard() {
    const [activeSection, setActiveSection] = useState("home");
    const [bots, setBots] = useState([]);
    const [isLoading, setIsLoading] = useState({});
    const [loadingTable, setLoadingTable] = useState(false);

    // Deploy states
    const fileInputRef = useRef(null);
    const [icon, setIcon] = useState(null);
    const [iconPreview, setIconPreview] = useState(null);
    const [files, setFiles] = useState([]);
    const [isDeployLoading, setIsDeployLoading] = useState(false);

    // Update Bot states (for model updates)
    const [isUpdateModalVisible, setIsUpdateModalVisible] = useState(false);
    const [selectedBot, setSelectedBot] = useState(null);
    const [isUpdating, setIsUpdating] = useState(false);
    const [updateFiles, setUpdateFiles] = useState([]);

    // **** New states for updating prompt instructions ****
    const [isUpdatePromptModalVisible, setIsUpdatePromptModalVisible] = useState(false);
    const [selectedBotForPrompt, setSelectedBotForPrompt] = useState(null);
    const [isUpdatingPrompt, setIsUpdatingPrompt] = useState(false);
    // *****************************************************


    // Ant Design form instance
    const [form] = Form.useForm();

    const server_url = "https://api.escoladeultrassom.com";
    const accessToken = localStorage.getItem("accessToken");
    const navigate = useNavigate();

    // -------------------------------------
    // Fetch bots from server
    // -------------------------------------
    const fetchBots = async () => {
        setLoadingTable(true);
        try {
            const response = await fetch(`${server_url}/all-bots/`, {
                method: "GET",
                headers: {Authorization: `Bearer ${accessToken}`},
            });
            if (response.ok) {
                const data = await response.json();
                // Insert default "General US.IA Assistant" if not present
                const hasDefault = data.some((b) => b.bot_name === "General US.IA Assistant");
                if (!hasDefault) {
                    data.unshift({
                        bot_name: "General US.IA Assistant",
                        isDefault: true, // We use this flag to handle updates
                    });
                }
                setBots(data);
            }
        } catch (error) {
            message.error("Error fetching bots: " + error.message);
        } finally {
            setLoadingTable(false);
        }
    };

    useEffect(() => {
        fetchBots();
    }, [accessToken]);

    // -------------------------------------
    // Deploy Agent (POST /deploy_agent_bot/)
    // -------------------------------------
    const handleIconChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => setIconPreview(reader.result);
            reader.readAsDataURL(file);
            setIcon(file);
        }
    };

    const handleFilesChange = (event) => {
        const selectedFiles = Array.from(event.target.files);
        setFiles(selectedFiles);
    };

    const handleCancelUpload = () => {
        setIcon(null);
        setIconPreview(null);
    };

    const handleDeploy = async (values) => {
        setIsDeployLoading(true);
        const formData = new FormData();
        formData.append("bot_name", values.bot_name);
        formData.append("bot_description", values.bot_description);

        if (values.instruction) {
            formData.append("instruction", values.instruction);
        }
        if (values.links) {
            const linksArray = values.links
                .split(",")
                .map((link) => link.trim())
                .filter((link) => link);
            linksArray.forEach((link) => formData.append("links", link));
        }
        if (files.length > 0) {
            files.forEach((file) => formData.append("files", file));
        }
        if (icon) {
            formData.append("icon", icon, icon.name);
        }

        try {
            const response = await fetch(`${server_url}/deploy_agent_bot/`, {
                method: "POST",
                headers: {Authorization: `Bearer ${accessToken}`},
                body: formData,
            });
            const data = await response.json();
            if (response.ok) {
                message.success("Bot deployed successfully!");
                await fetchBots();
                form.resetFields();
                setIcon(null);
                setIconPreview(null);
                setFiles([]);
            } else {
                message.error(data.message || "Failed to deploy bot.");
            }
        } catch (error) {
            message.error("Error deploying bot: " + error.message);
        } finally {
            setIsDeployLoading(false);
        }
    };

    // -------------------------------------
    // Update Bot (for files/links update)
    //   - Default Bot => POST /update_model/
    //   - Other Bots => PUT /agent_update_model/
    // -------------------------------------
    const showUpdateModal = (bot) => {
        setSelectedBot(bot);
        setIsUpdateModalVisible(true);
    };

    const closeUpdateModal = () => {
        setSelectedBot(null);
        setIsUpdateModalVisible(false);
        setUpdateFiles([]);
    };

    const handleUpdateFilesChange = (event) => {
        const selectedFiles = Array.from(event.target.files);
        setUpdateFiles(selectedFiles);
    };

    const handleUpdateBot = async (values, bot) => {
        if (!bot) return;
        setIsUpdating(true);

        // Build FormData with files/links
        const formData = new FormData();
        if (values.links) {
            const linksArray = values.links
                .split(",")
                .map((link) => link.trim())
                .filter((link) => link);
            linksArray.forEach((link) => formData.append("links", link));
        }
        if (updateFiles.length > 0) {
            updateFiles.forEach((file) => formData.append("files", file));
        }

        try {
            let url = "";
            let method = "";
            if (bot.isDefault) {
                // Default: POST /update_model/
                url = `${server_url}/update_model/`;
                method = "POST";
            } else {
                // Others: PUT /agent_update_model/
                if (!bot.bot_id) {
                    message.error("Cannot update: missing bot_id.");
                    setIsUpdating(false);
                    return;
                }
                formData.append("bot_id", bot.bot_id);
                url = `${server_url}/agent_update_model/`;
                method = "PUT";
            }

            const response = await fetch(url, {
                method,
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
                body: formData,
            });

            if (response.ok) {
                message.success("Bot updated successfully!");
                await fetchBots();
                closeUpdateModal();
            } else {
                const errorData = await response.json();
                message.error(errorData.message || "Failed to update bot.");
            }
        } catch (error) {
            message.error("Error updating bot: " + error.message);
        } finally {
            setIsUpdating(false);
        }
    };

    // -------------------------------------
    // Delete Bot (DELETE /agents/:bot_id)
    // -------------------------------------
    const deleteBot = async (botId) => {
        try {
            const response = await fetch(`${server_url}/agents/${botId}`, {
                method: "DELETE",
                headers: {Authorization: `Bearer ${accessToken}`},
            });
            if (response.ok) {
                setBots((prev) => prev.filter((bot) => bot.bot_id !== botId));
                message.success("Bot deleted successfully!");
            } else {
                message.error("Failed to delete bot.");
            }
        } catch (error) {
            message.error("Error deleting bot: " + error.message);
        }
    };

    // -------------------------------------
    // New: Update Prompt Instructions
    // -------------------------------------
    const [instruction, setInstruction] = useState("");

    // 2) Function to show modal and fetch current instructions:
    const showUpdatePromptModal = (bot) => {
        setSelectedBotForPrompt(bot);
        fetch(`${server_url}/agent_instructions/${bot.bot_id}`, {
            method: "GET",
            headers: {Authorization: `Bearer ${accessToken}`},
        })
            .then((res) => res.json())
            .then((data) => {
                // Pre-fill the single text area with the current instruction
                setInstruction(data.instruction || "");
                setIsUpdatePromptModalVisible(true);
            })
            .catch((err) => {
                message.error("Error fetching current instructions: " + err.message);
            });
    };

    // 3) Function to submit the updated instruction:
    const handleUpdatePrompt = async () => {
        if (!selectedBotForPrompt) return;
        setIsUpdatingPrompt(true);
        const formData = new FormData();
        formData.append("bot_id", selectedBotForPrompt.bot_id);
        formData.append("instruction", instruction);
        try {
            const response = await fetch(`${server_url}/agent_instructions`, {
                method: "PUT",
                headers: {Authorization: `Bearer ${accessToken}`},
                body: formData,
            });
            if (response.ok) {
                message.success("Prompt instructions updated successfully!");
                setIsUpdatePromptModalVisible(false);
            } else {
                const errorData = await response.json();
                message.error(errorData.message || "Failed to update prompt instructions.");
            }
        } catch (error) {
            message.error("Error updating prompt instructions: " + error.message);
        } finally {
            setIsUpdatingPrompt(false);
        }
    };

    // 4) Function to close the modal and reset state:
    const closeUpdatePromptModal = () => {
        setSelectedBotForPrompt(null);
        setInstruction("");
        setIsUpdatePromptModalVisible(false);
    };
    // -------------------------------------
    // Sidebar Navigation
    // -------------------------------------
    const handleLogout = () => {
        localStorage.removeItem("accessToken");
        navigate("/");
    };

    // -------------------------------------
    // Render Pages
    // -------------------------------------
    const renderHome = () => <HomePage/>;

    const renderUserView = () => <UserView/>;

    const renderDeployAgents = () => (
        <div style={{padding: "40px", maxWidth: "1000px", margin: "0 auto"}}>
            <div style={{textAlign: "center", marginBottom: "30px"}}>
                <Title level={2}>Deploy New Bot</Title>
                <Paragraph>
                    Fill in the details to create and deploy a new chatbot agent.
                </Paragraph>
            </div>
            <Card bordered={false} style={{borderRadius: "8px", padding: "20px"}}>
                <Form form={form} layout="vertical" onFinish={handleDeploy}>
                    <div className="upload-section" style={{marginBottom: "20px"}}>
                        <label
                            className="icon-input-label"
                            style={{
                                backgroundImage: iconPreview
                                    ? `url(${iconPreview})`
                                    : 'url("data:image/svg+xml,%3Csvg xmlns=\'http://www.w3.org/2000/svg\' fill=\'%23000\' viewBox=\'0 0 24 24\'%3E%3Cpath d=\'M10 8H8v2H6v2h2v2h2v-2h2v-2h-2z\'/%3E%3Cpath d=\'M5 2C3.89 2 3 2.9 3 4v16c0 1.1.89 2 2 2h14c1.11 0 2-.9 2-2V4c0-1.1-.89-2-2-2H5zm0 2h14v16H5V4z\'/%3E%3C/svg%3E")',
                                backgroundSize: iconPreview ? "cover" : "30%",
                                backgroundPosition: "center",
                                backgroundRepeat: "no-repeat",
                                display: "inline-block",
                                width: "80px",
                                height: "80px",
                                borderRadius: "50%",
                                cursor: "pointer",
                            }}
                        >
                            <input
                                type="file"
                                accept="image/*"
                                onChange={handleIconChange}
                                className="icon-input"
                                style={{display: "none"}}
                            />
                        </label>
                        {iconPreview && (
                            <Button
                                type="text"
                                danger
                                onClick={handleCancelUpload}
                                style={{marginLeft: "10px"}}
                            >
                                Remove Image
                            </Button>
                        )}
                    </div>
                    <Form.Item
                        name="bot_name"
                        label="Bot Name"
                        rules={[{required: true, message: "Please enter the bot name"}]}
                    >
                        <Input placeholder="Enter bot name"/>
                    </Form.Item>
                    <Form.Item
                        name="bot_description"
                        label="Bot Description"
                        rules={[{required: true, message: "Please enter the bot description"}]}
                    >
                        <Input.TextArea rows={2} placeholder="Enter bot description"/>
                    </Form.Item>
                    <Form.Item name="instruction" label="Instruction">
                        <Input.TextArea rows={2} placeholder="Enter instruction (optional)"/>
                    </Form.Item>
                    <Form.Item name="links" label="Links">
                        <Input placeholder="Enter links (comma-separated)"/>
                    </Form.Item>
                    <Form.Item name="files" label="Upload Files">
                        <input type="file" multiple ref={fileInputRef} onChange={handleFilesChange}/>
                    </Form.Item>
                    <Form.Item>
                        <Button
                            type="primary"
                            htmlType="submit"
                            loading={isDeployLoading}
                            style={{width: "100%"}}
                        >
                            Deploy Bot
                        </Button>
                    </Form.Item>
                </Form>
            </Card>
        </div>
    );

    const renderManageAgents = () => {
        return (
            <div style={{padding: "40px", maxWidth: "1400px", margin: "0 auto"}}>
                <div style={{textAlign: "center", marginBottom: "30px"}}>
                    <Title level={2}>Manage Chatbots</Title>
                    <Paragraph>
                        View, update, or delete your chatbots. The General US.IA Assistant
                        is included by default.
                    </Paragraph>
                </div>
                <Card bordered={false} style={{borderRadius: "8px", padding: "20px"}}>
                    {loadingTable ? (
                        <div style={{textAlign: "center", marginTop: "50px"}}>
                            <Spin size="large"/>
                        </div>
                    ) : (
                        <Table
                            dataSource={bots.map((bot, index) => ({key: index, ...bot}))}
                            columns={[
                                {
                                    title: "Icon",
                                    dataIndex: "icon_path",
                                    key: "icon",
                                    align: "center",
                                    render: (iconPath, record) => {
                                        if (record.isDefault) {
                                            return (
                                                <Avatar
                                                    style={{backgroundColor: "#1890ff"}}
                                                    size={64}
                                                    alt="Assistant Icon"
                                                >
                                                    GA
                                                </Avatar>
                                            );
                                        }
                                        return iconPath ? (
                                            <Avatar
                                                src={`${server_url}/${iconPath}`}
                                                size={64}
                                                shape="circle"
                                                alt="Bot Icon"
                                            />
                                        ) : (
                                            <Avatar size={64} style={{backgroundColor: "#ccc"}}>
                                                N/A
                                            </Avatar>
                                        );
                                    },
                                },
                                {
                                    title: "Bot Name",
                                    dataIndex: "bot_name",
                                    key: "bot_name",
                                    align: "center",
                                },
                                {
                                    title: "Description",
                                    dataIndex: "description",
                                    key: "description",
                                    align: "center",
                                    render: (text) =>
                                        text ? (
                                            <Tooltip title={text}>
                        <span
                            style={{
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                maxWidth: "200px",
                                display: "inline-block",
                            }}
                        >
                          {text}
                        </span>
                                            </Tooltip>
                                        ) : (
                                            "General US.IA Assistant"
                                        ),
                                },
                                {
                                    title: "Actions",
                                    key: "actions",
                                    align: "center",
                                    render: (_, record) => (
                                        <Space size="middle">
                                            <Tooltip title="Update Bot">
                                                <Button
                                                    icon={<UploadOutlined/>}
                                                    onClick={() => showUpdateModal(record)}
                                                    loading={isLoading[record.bot_id]}
                                                />
                                            </Tooltip>
                                            {/* New "Update Prompt" button */}
                                            {record.bot_id && (
                                                <Tooltip title="Update Prompt">
                                                    <Button
                                                        icon={<EditOutlined/>}
                                                        onClick={() => showUpdatePromptModal(record)}
                                                        loading={isLoading[record.bot_id]}
                                                    />
                                                </Tooltip>
                                            )}
                                            {!record.isDefault && record.bot_id && (
                                                <Tooltip title="Delete Bot">
                                                    <Popconfirm
                                                        title="Are you sure you want to delete this bot?"
                                                        onConfirm={() => deleteBot(record.bot_id)}
                                                        okText="Yes"
                                                        cancelText="No"
                                                    >
                                                        <Button icon={<DeleteOutlined/>} danger/>
                                                    </Popconfirm>
                                                </Tooltip>
                                            )}
                                        </Space>
                                    ),
                                },
                            ]}
                            pagination={{
                                pageSize: 5,
                                showSizeChanger: true,
                                pageSizeOptions: ["5", "10", "15"],
                            }}
                            bordered
                            rowClassName={(record) =>
                                record.bot_name === "General US.IA Assistant" ? "highlighted-row" : ""
                            }
                        />
                    )}
                </Card>

                {/* Update Bot Modal (existing) */}
                <Modal
                    title={`Update Bot Model${selectedBot?.isDefault ? " (General Assistant)" : ""}`}
                    open={isUpdateModalVisible}
                    onCancel={closeUpdateModal}
                    footer={null}
                    width={600}
                >
                    <Form
                        layout="vertical"
                        onFinish={(values) => handleUpdateBot(values, selectedBot)}
                    >
                        <Form.Item name="links" label="Links (comma-separated)">
                            <Input placeholder="Enter links (comma-separated)"/>
                        </Form.Item>
                        <Form.Item name="files" label="Upload Files">
                            <input type="file" multiple onChange={handleUpdateFilesChange}/>
                        </Form.Item>
                        <Form.Item>
                            <Button type="primary" htmlType="submit" loading={isUpdating} block>
                                Update Bot Model
                            </Button>
                        </Form.Item>
                    </Form>
                </Modal>

                {/* New: Update Prompt Modal */}
                <Modal
                    title="Update Prompt Instructions"
                    open={isUpdatePromptModalVisible}
                    onCancel={closeUpdatePromptModal}
                    footer={[
                        <Button key="cancel" onClick={closeUpdatePromptModal}>
                            Cancel
                        </Button>,
                        <Button
                            key="submit"
                            type="primary"
                            loading={isUpdatingPrompt}
                            onClick={handleUpdatePrompt}
                        >
                            Update Prompt
                        </Button>,
                    ]}
                    width={600}
                >
                    <Form layout="vertical">
                        <Form.Item label="Prompt Instructions">
                            <Input.TextArea
                                rows={6}
                                value={instruction}
                                onChange={(e) => setInstruction(e.target.value)}
                                placeholder="Edit custom instructions here"
                            />
                        </Form.Item>
                    </Form>
                </Modal>
            </div>
        );
    };

    const renderSettings = () => <Settings server_url={server_url}/>;

    // -------------------------------------
    // Conditional Rendering by activeSection
    // -------------------------------------
    const renderContent = () => {
        switch (activeSection) {
            case "home":
                return renderHome();
            case "userView":
                return renderUserView();
            case "deploy_agents":
                return renderDeployAgents();
            case "manage_agents":
                return renderManageAgents();
            case "settings":
                return renderSettings();
            default:
                return renderHome();
        }
    };

    return (
        <div className="dashboard-container">
            <aside className="sidebar">
                <div className="logo-container">
                    <img src={bot} alt="logo" className="app-logo"/>
                </div>
                <button
                    onClick={() => setActiveSection("home")}
                    className={activeSection === "home" ? "active" : ""}
                >
                    <HomeOutlined style={{marginRight: 8}}/>
                    Home
                </button>
                <button
                    onClick={() => setActiveSection("userView")}
                    className={activeSection === "userView" ? "active" : ""}
                >
                    <UserOutlined style={{marginRight: 8}}/>
                    View Users
                </button>
                <button
                    onClick={() => setActiveSection("deploy_agents")}
                    className={activeSection === "deploy_agents" ? "active" : ""}
                >
                    <RocketOutlined style={{marginRight: 8}}/>
                    Deploy Agents
                </button>
                <button
                    onClick={() => setActiveSection("manage_agents")}
                    className={activeSection === "manage_agents" ? "active" : ""}
                >
                    <RobotOutlined style={{marginRight: 8}}/>
                    Manage Agents
                </button>
                <button
                    onClick={() => setActiveSection("settings")}
                    className={activeSection === "settings" ? "active" : ""}
                >
                    <SettingOutlined style={{marginRight: 8}}/>
                    Settings
                </button>
                <button onClick={handleLogout}>
                    <LogoutOutlined style={{marginRight: 8}}/>
                    Logout
                </button>
            </aside>
            <main className="main-content">{renderContent()}</main>
        </div>
    );
}

export default Dashboard;
