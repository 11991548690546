import React, { useState } from "react";
import { Form, Input, Button, Select, Card, message, Upload, Tabs, Typography } from "antd";
import { EyeOutlined, EyeInvisibleOutlined, UploadOutlined } from "@ant-design/icons";

const { Option } = Select;
const { Title, Paragraph } = Typography;
const { TabPane } = Tabs;

const Settings = () => {
  const server_url = "https://api.escoladeultrassom.com";
  const accessToken = localStorage.getItem("accessToken");

  // Add User State
  const [newUserData, setNewUserData] = useState({
    name: "",
    email: "",
    password: "",
    role: "user",
  });
  const [loadingUser, setLoadingUser] = useState(false);

  // Update Admin Password State
  const [passwordData, setPasswordData] = useState({
    name: "",
    email: "",
    password: "",
    confirmPassword: "",
  });
  const [loadingPassword, setLoadingPassword] = useState(false);

  // Update Chatbot Model State
  const [modelFiles, setModelFiles] = useState([]);
  const [modelLinks, setModelLinks] = useState("");
  const [loadingModel, setLoadingModel] = useState(false);

  // Handlers for Add User
  const handleNewUserInputChange = (key, value) => {
    setNewUserData({ ...newUserData, [key]: value });
  };

  const createNewUser = async () => {
    setLoadingUser(true);
    try {
      const response = await fetch(`${server_url}/admin/create-user/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify(newUserData),
      });
      const data = await response.json();
      if (response.ok) {
        message.success(data.message || "User created successfully");
        setNewUserData({ name: "", email: "", password: "", role: "user" });
      } else {
        message.error(data.detail || "Failed to create user");
      }
    } catch (error) {
      console.error(error);
      message.error("An error occurred while creating the user");
    } finally {
      setLoadingUser(false);
    }
  };

  // Handlers for Update Password
  const handlePasswordInputChange = (key, value) => {
    setPasswordData({ ...passwordData, [key]: value });
  };

  const updatePassword = async () => {
    if (passwordData.password !== passwordData.confirmPassword) {
      message.error("Passwords do not match!");
      return;
    }
    setLoadingPassword(true);
    try {
      const response = await fetch(`${server_url}/user/update`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify({
          name: passwordData.name,
          email: passwordData.email,
          password: passwordData.password,
        }),
      });
      const data = await response.json();
      if (response.ok) {
        message.success(data.message || "Password updated successfully");
        setPasswordData({ name: "", email: "", password: "", confirmPassword: "" });
      } else {
        message.error(data.detail || "Failed to update password");
      }
    } catch (error) {
      console.error(error);
      message.error("An error occurred while updating the password");
    } finally {
      setLoadingPassword(false);
    }
  };

  // Handlers for Update Chatbot Model
  const handleModelFileChange = ({ fileList }) => {
    setModelFiles(fileList.map((file) => file.originFileObj));
  };

  const handleModelLinkChange = (e) => {
    setModelLinks(e.target.value);
  };

  const updateModel = async () => {
    if (modelFiles.length === 0 && modelLinks.trim() === "") {
      message.warning("Please provide at least one file or link to update the model.");
      return;
    }
    const formData = new FormData();
    modelFiles.forEach((file) => {
      formData.append("files", file);
    });
    formData.append("link", modelLinks);
    setLoadingModel(true);
    try {
      const response = await fetch(`${server_url}/update_model/`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        body: formData,
      });
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.detail || "Failed to update the model.");
      }
      message.success("Model updated successfully!");
      setModelFiles([]);
      setModelLinks("");
    } catch (error) {
      console.error("Error updating the model:", error);
      message.error(error.message || "Failed to update the model.");
    } finally {
      setLoadingModel(false);
    }
  };

  return (
    <div style={{ padding: "40px", maxWidth: "1000px", margin: "0 auto" }}>
      <div style={{ textAlign: "center", marginBottom: "30px" }}>
        <Title level={2}>Settings</Title>
        <Paragraph>
          Manage your account settings, create users, update your admin password, and update the chatbot model.
        </Paragraph>
      </div>
      <Tabs defaultActiveKey="1" centered>
        <TabPane tab="Add User" key="1">
          <Card title="Add New User" bordered style={{ margin: "20px" }}>
            <Form layout="vertical" onFinish={createNewUser}>
              <Form.Item label="Name" required>
                <Input
                  placeholder="Enter name"
                  value={newUserData.name}
                  onChange={(e) => handleNewUserInputChange("name", e.target.value)}
                />
              </Form.Item>
              <Form.Item label="Email" required>
                <Input
                  placeholder="Enter email"
                  value={newUserData.email}
                  onChange={(e) => handleNewUserInputChange("email", e.target.value)}
                />
              </Form.Item>
              <Form.Item label="Password" required>
                <Input.Password
                  placeholder="Enter password"
                  iconRender={(visible) => (visible ? <EyeOutlined /> : <EyeInvisibleOutlined />)}
                  value={newUserData.password}
                  onChange={(e) => handleNewUserInputChange("password", e.target.value)}
                />
              </Form.Item>
              <Form.Item label="Role">
                <Select
                  value={newUserData.role}
                  onChange={(value) => handleNewUserInputChange("role", value)}
                >
                  <Option value="user">User</Option>
                  <Option value="admin">Admin</Option>
                </Select>
              </Form.Item>
              <Form.Item>
                <Button type="primary" htmlType="submit" loading={loadingUser}>
                  Create User
                </Button>
              </Form.Item>
            </Form>
          </Card>
        </TabPane>
        <TabPane tab="Update Admin Password" key="2">
          <Card title="Update Admin Password" bordered style={{ margin: "20px" }}>
            <Form layout="vertical" onFinish={updatePassword}>
              <Form.Item label="Name" required>
                <Input
                  placeholder="Enter name"
                  value={passwordData.name}
                  onChange={(e) => handlePasswordInputChange("name", e.target.value)}
                />
              </Form.Item>
              <Form.Item label="Email" required>
                <Input
                  placeholder="Enter email"
                  value={passwordData.email}
                  onChange={(e) => handlePasswordInputChange("email", e.target.value)}
                />
              </Form.Item>
              <Form.Item label="New Password" required>
                <Input.Password
                  placeholder="Enter new password"
                  iconRender={(visible) => (visible ? <EyeOutlined /> : <EyeInvisibleOutlined />)}
                  value={passwordData.password}
                  onChange={(e) => handlePasswordInputChange("password", e.target.value)}
                />
              </Form.Item>
              <Form.Item label="Confirm Password" required>
                <Input.Password
                  placeholder="Confirm new password"
                  iconRender={(visible) => (visible ? <EyeOutlined /> : <EyeInvisibleOutlined />)}
                  value={passwordData.confirmPassword}
                  onChange={(e) => handlePasswordInputChange("confirmPassword", e.target.value)}
                />
              </Form.Item>
              <Form.Item>
                <Button type="primary" htmlType="submit" loading={loadingPassword}>
                  Update Password
                </Button>
              </Form.Item>
            </Form>
          </Card>
        </TabPane>
        {/*<TabPane tab="Update Chatbot Model" key="3">*/}
        {/*  <Card title="Update Chatbot Model" bordered style={{ margin: "20px" }}>*/}
        {/*    <Form layout="vertical">*/}
        {/*      <Form.Item>*/}
        {/*        <Upload*/}
        {/*          multiple*/}
        {/*          beforeUpload={() => false}*/}
        {/*          onChange={handleModelFileChange}*/}
        {/*          fileList={modelFiles.map((file) => ({ name: file.name }))}*/}
        {/*        >*/}
        {/*          <Button icon={<UploadOutlined />}>Upload Files</Button>*/}
        {/*        </Upload>*/}
        {/*      </Form.Item>*/}
        {/*      <Form.Item>*/}
        {/*        <Input*/}
        {/*          placeholder="Enter a link"*/}
        {/*          value={modelLinks}*/}
        {/*          onChange={handleModelLinkChange}*/}
        {/*        />*/}
        {/*      </Form.Item>*/}
        {/*      <Form.Item>*/}
        {/*        <Button*/}
        {/*          type="primary"*/}
        {/*          onClick={updateModel}*/}
        {/*          loading={loadingModel}*/}
        {/*          style={{ width: "100%" }}*/}
        {/*        >*/}
        {/*          Update Model*/}
        {/*        </Button>*/}
        {/*      </Form.Item>*/}
        {/*    </Form>*/}
        {/*  </Card>*/}
        {/*</TabPane>*/}
      </Tabs>
    </div>
  );
};

export default Settings;
