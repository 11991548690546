import React, { useEffect, useState } from "react";
import { Row, Col, Card, Typography, Space, Spin } from "antd";
import {
  UserOutlined,
  LineChartOutlined,
  UserAddOutlined,
  CalendarOutlined,
  RiseOutlined,
} from "@ant-design/icons";

const { Title, Text, Paragraph } = Typography;

const statsData = [
  { key: "total_users", title: "Total Users", icon: <UserOutlined />, color: "#007bff" },
  { key: "new_users_last_month", title: "New Users Last Month", icon: <UserAddOutlined />, color: "#28a745" },
  { key: "monthly_growth", title: "Monthly Growth", icon: <LineChartOutlined />, color: "#ffc107" },
  { key: "new_users_last_week", title: "New Users Last Week", icon: <CalendarOutlined />, color: "#17a2b8" },
  { key: "weekly_growth", title: "Weekly Growth", icon: <RiseOutlined />, color: "#dc3545" },
  { key: "new_users_last_day", title: "New Users Last Day", icon: <CalendarOutlined />, color: "#6f42c1" },
];

const HomePage = () => {
  const [userStats, setUserStats] = useState({
    total_users: 0,
    new_users_last_month: 0,
    monthly_growth: "0.00%",
    new_users_last_week: 0,
    weekly_growth: "0.00%",
    new_users_last_day: 0,
  });
  const [loading, setLoading] = useState(false);
  const server_url = "https://api.escoladeultrassom.com";

  useEffect(() => {
    const fetchStats = async () => {
      setLoading(true);
      const accessToken = localStorage.getItem("accessToken");
      try {
        const response = await fetch(`${server_url}/admin/user-stats`, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });
        if (response.ok) {
          const data = await response.json();
          setUserStats(data);
        } else {
          console.error("Failed to fetch stats");
        }
      } catch (error) {
        console.error("Error fetching stats:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchStats();
  }, []);

  const cardStyle = {
    background: "#ffffff",
    borderRadius: "12px",
    textAlign: "center",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.05)",
    padding: "20px",
    transition: "transform 0.3s, box-shadow 0.3s",
    border: "none",
  };

  return (
    <div style={{ padding: "40px", maxWidth: "1200px", margin: "0 auto" }}>
      <Title level={2} style={{ textAlign: "center", color: "#333", marginBottom: "10px" }}>
        App Statistics
      </Title>
      <Paragraph style={{ textAlign: "center", color: "#555", marginBottom: "30px" }}>
        Discover key metrics that reveal your user engagement and growth trends.
      </Paragraph>
      {loading ? (
        <div style={{ textAlign: "center", marginTop: "50px" }}>
          <Spin size="large" />
        </div>
      ) : (
        <Row gutter={[24, 24]}>
          {statsData.map(({ key, title, icon, color }) => (
            <Col xs={24} sm={12} lg={8} key={key}>
              <Card
                hoverable
                style={cardStyle}
                bodyStyle={{ padding: "30px" }}
                onMouseEnter={(e) => {
                  e.currentTarget.style.transform = "scale(1.05)";
                  e.currentTarget.style.boxShadow = "0 6px 12px rgba(0, 0, 0, 0.1)";
                }}
                onMouseLeave={(e) => {
                  e.currentTarget.style.transform = "scale(1)";
                  e.currentTarget.style.boxShadow = "0 4px 8px rgba(0, 0, 0, 0.05)";
                }}
              >
                <Space direction="vertical" align="center" size="middle">
                  <div style={{ color, fontSize: "40px" }}>{icon}</div>
                  <Title level={4} style={{ margin: "10px 0" }}>
                    {title}
                  </Title>
                  <Text strong style={{ fontSize: "24px" }}>
                    {userStats[key]}
                  </Text>
                </Space>
              </Card>
            </Col>
          ))}
        </Row>
      )}
    </div>
  );
};

export default HomePage;
