import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  message,
  Popconfirm,
  Spin,
  Table,
  Typography,
  Modal,
  Form,
  Input,
  Select
} from "antd";
import { DownloadOutlined, EditOutlined, DeleteOutlined } from "@ant-design/icons";
import "./UserView.css"; // <-- Import your custom CSS

const { Title, Paragraph } = Typography;
const { Option } = Select;

const UserView = () => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [form] = Form.useForm();
  const server_url = "https://api.escoladeultrassom.com";
  const accessToken = localStorage.getItem("accessToken");

  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = async () => {
    setLoading(true);
    try {
      const response = await fetch(`${server_url}/admin/get-users/`, {
        headers: { Authorization: `Bearer ${accessToken}` },
      });
      if (response.ok) {
        const data = await response.json();
        setUsers(data);
      } else {
        message.error("Failed to fetch users");
      }
    } catch (error) {
      console.error("Error fetching users:", error);
      message.error("An error occurred while fetching users");
    } finally {
      setLoading(false);
    }
  };

  const deleteUser = async (email) => {
    try {
      const response = await fetch(`${server_url}/admin/delete-user/`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify({ email }),
      });
      const data = await response.json();
      if (response.ok) {
        message.success(data.detail);
        setUsers(users.filter((user) => user.email !== email));
      } else {
        message.error(data.detail || "Failed to delete user");
      }
    } catch (error) {
      console.error("Error deleting user:", error);
      message.error("An error occurred while deleting the user");
    }
  };

  const exportUsers = async () => {
    try {
      const response = await fetch(`${server_url}/admin/export-users/`, {
        headers: { Authorization: `Bearer ${accessToken}` },
      });
      if (response.ok) {
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = "users.csv";
        document.body.appendChild(a);
        a.click();
        a.remove();
      } else {
        message.error("Failed to export users");
      }
    } catch (error) {
      console.error("Error exporting users:", error);
      message.error("An error occurred while exporting users");
    }
  };

  const showUpdateSubscriptionModal = (user) => {
    setSelectedUser(user);
    form.setFieldsValue({
      email: user.email,
      plan_type: user.plan_name ? user.plan_name.toLowerCase() : "monthly",
    });
    setIsModalVisible(true);
  };

  const handleUpdateSubscription = async (values) => {
    try {
      const response = await fetch(`${server_url}/admin/update-subscription/`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify(values),
      });
      const data = await response.json();
      if (response.ok) {
        message.success(data.detail);
        setIsModalVisible(false);
        setUsers(
          users.map((user) =>
            user.email === values.email
              ? { ...user, subscription_status: "active", plan_name: values.plan_type }
              : user
          )
        );
      } else {
        message.error(data.detail || "Failed to update subscription");
      }
    } catch (error) {
      console.error("Error updating subscription:", error);
      message.error("An error occurred while updating subscription");
    }
  };

  const columns = [
    { title: "Name", dataIndex: "name", key: "name" },
    { title: "Email", dataIndex: "email", key: "email" },
    { title: "Role", dataIndex: "role", key: "role" },
    { title: "Auth Method", dataIndex: "auth_method", key: "auth_method" },
    { title: "Query Count", dataIndex: "query_count", key: "query_count" },
    { title: "Query Limit", dataIndex: "query_limit", key: "query_limit" },
    { title: "Subscription", dataIndex: "subscription_status", key: "subscription_status" },
    { title: "Plan", dataIndex: "plan_name", key: "plan_name" },
    {
      title: "Created At",
      dataIndex: "created_at",
      key: "created_at",
      render: (text) => new Date(text).toLocaleString(),
    },
    {
      title: "Actions",
      key: "actions",
      render: (_, record) => (
        <div style={{ display: "flex", flexDirection: "column", gap: "8px" }}>
          <Button
            className="gradient-button-admin update-sub-button-admin"
            icon={<EditOutlined />}
            onClick={() => showUpdateSubscriptionModal(record)}
          >
            Update Subscription
          </Button>
          <Popconfirm
            title="Are you sure you want to delete this user?"
            onConfirm={() => deleteUser(record.email)}
            okText="Yes"
            cancelText="No"
          >
            <Button className="gradient-button-admin delete-button-admin" icon={<DeleteOutlined />}>
              Delete
            </Button>
          </Popconfirm>
        </div>
      ),
    },
  ];

  return (
    <div style={{ padding: "40px", maxWidth: "1400px", margin: "0 auto" }}>
      <div style={{ textAlign: "center", marginBottom: "30px" }}>
        <Title level={2} style={{ marginBottom: "5px", color: "#333" }}>
          App Users Management
        </Title>
        <Paragraph style={{ color: "#555", fontSize: "16px" }}>
          Manage registered users and update their subscription plans effortlessly.
        </Paragraph>
        <Button
          className="gradient-button-admin export-button-admin"
          onClick={exportUsers}
          style={{ marginTop: "20px" }}
          icon={<DownloadOutlined />}
        >
          Export Users CSV
        </Button>
      </div>
      <Card
        bordered={false}
        style={{
          backgroundColor: "#fff",
          borderRadius: "8px",
          padding: "20px",
          boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)",
        }}
      >
        {loading ? (
          <div style={{ textAlign: "center", marginTop: "50px" }}>
            <Spin size="large" />
          </div>
        ) : (
          <Table
            dataSource={users}
            columns={columns}
            rowKey="email"
            pagination={{ pageSize: 10 }}
            bordered
            style={{ borderRadius: "8px" }}
          />
        )}
      </Card>
      <Modal
        title="Update User Subscription"
        visible={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        onOk={() => {
          form
            .validateFields()
            .then((values) => handleUpdateSubscription(values))
            .catch((info) => console.log("Validation Failed:", info));
        }}
        okText="Update Subscription"
      >
        <Form form={form} layout="vertical">
          <Form.Item name="email" label="Email">
            <Input disabled />
          </Form.Item>
          <Form.Item
            name="plan_type"
            label="Plan Type"
            rules={[{ required: true, message: "Please select a plan type" }]}
          >
            <Select placeholder="Select a plan type">
              <Option value="monthly">Monthly</Option>
              <Option value="yearly">Yearly</Option>
            </Select>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default UserView;
